import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ExtraFooterShell from 'components/flex/ExtraFooter/ExtraFooterShell'

import LayoutPage from 'components/layout/LayoutPage'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import FormApply from 'components/elements/FormApply'
import CheckIcon from 'img/check.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Quote from 'img/quote.inline.svg'

// import { HelmetProvider } from 'react-helmet-async'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      // eslint-disable-next-line
      flexContent: GatsbyTypes.WpPage_Flexcontent
      // eslint-disable-next-line
      vacancyFields: GatsbyTypes.WpVacancy_Vacancyfields
      link: any
    }
    footerData: any
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { flexContent, vacancyFields, seo, title, link },
    footerData,
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <LayoutPage
      title={title || ''}
      prefix={prefix}
      flexContent={flexContent}
      location={location}
      pageContext={pageContext}
      beforeContent={
        <BeforeContent
          items={vacancyFields.usps}
          facebook={footerData.footer.menu.facebook}
          linkedin={footerData.footer.menu.linkedin}
        />
      }
    />
    <AfterContent
      vacancyTitle={title || ''}
      vacancyUrl={link}
      image={vacancyFields.apply?.image}
      quote={vacancyFields.apply?.quote}
    />
    <ExtraFooterShell fields={flexContent.extrafooter} location={location} />
  </Layout>
)

/**
 * BeforeContent, usps and button
 */
const Usp = styled.div`
  svg {
    width: 22px;
    height: 34px;
  }
`

const SocialIcon = styled.a`
  width: 27px;
  height: 27px;
`

function BeforeContent({
  items,
  linkedin,
  facebook,
}: {
  items?: readonly any[]
  linkedin?: any
  facebook?: any
}) {
  if (!items) return null

  return (
    <div className="mb-4">
      <div className="d-flex flex-wrap mb-4">
        {items.map(({ text }, index) => (
          <Usp
            className="d-flex align-items-center font-weight-bold font-size-big me-lg-5 px-lg-0 px-2"
            key={index}
          >
            <CheckIcon className="me-lg-4 me-2" />
            {text}
          </Usp>
        ))}
      </div>

      <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex flex-wrap align-items-end gap-2">
          <ButtonPrimary isAnchor to="#applyForm">
            Solliciteer direct
          </ButtonPrimary>

          {/* <StyledButtonPrimary
            to="https://api.whatsapp.com/send?phone=31627349050"
            target="_blank"
          >
            Contact via WhatsApp
          </StyledButtonPrimary> */}
        </div>

        <div
          className="d-flex pt-lg-0 pt-4 align-items-center"
          style={{ opacity: 0.4 }}
        >
          <div className="me-3">Deel deze vacature:</div>
          <SocialIcon className="me-2" href={linkedin?.url}>
            <Linkedin className="w-100 h-100" />
          </SocialIcon>
          <SocialIcon className="me-2" href={facebook?.url}>
            <Facebook className="w-100 h-100" />
          </SocialIcon>
        </div>
      </div>
    </div>
  )
}

/**
 * AfterContent, image, quote and form
 */

const StyledImage = styled(Plaatjie)`
  width: 300px;
  height: 380px;
  flex: none;

  @media screen and (max-width: 991px) {
    width: 60%;
    height: auto;
  }
`

const QuoteWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;

  svg {
    flex: none;
  }

  .quoteContent {
    width: 384px;

    p {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  @media screen and (max-width: 991px) {
    .quoteContent {
      width: 100%;
    }
  }
`

const FormWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`

function AfterContent({
  image,
  quote,
  vacancyUrl,
  vacancyTitle,
}: {
  image: any
  quote?: string
  vacancyUrl: string
  vacancyTitle: string
}) {
  return (
    <div className="container mb-5">
      <QuoteWrapper className="d-flex flex-wrap-reverse align-items-center justify-content-center">
        <StyledImage image={image} alt="" />
        <div>
          <div className="d-flex">
            <Quote className="me-3" />
            <ParseContent
              className="quoteContent font-weight-bold pt-5"
              content={quote || ''}
            />
          </div>
        </div>
      </QuoteWrapper>

      <div className="w-100 bg-primary p-lg-5 px-2 py-4">
        <FormWrapper>
          <h2 className="text-white text-center">Solliciteren?</h2>

          <FormApply
            vacancyName={`${vacancyTitle} | https://www.unit14.nl${vacancyUrl}`}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }

    footerData: wpComponent(databaseId: { eq: 98 }) {
      footer {
        menu {
          mail {
            title
            url
          }
          linkedin {
            title
            url
          }
          facebook {
            title
            url
          }
        }
      }
    }
  }
`

export default PageTemplate
